import { ActionsObservable, Epic } from "redux-observable";
import { from, of } from "rxjs";
import { catchError, filter, startWith, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";

// APIs
import * as itemTypesCategoriesApi from "api/itemTypesCategories/itemTypesCategoriesApi";

// Selectors
import { currentUserTokenSelector } from "store/features/currentUser/selectors";

// Slices
import { notificationSlice } from "store/features/notification/slice";
import { itemTypesCategoriesSlice } from "../slice";

// Types
import { FetchResponse } from "modules/api/types";
import ItemTypeCategory from "types/entities/itemTypeCategory";

const fetchByIdSuccessHandler = ({
  data: entity,
}: FetchResponse<ItemTypeCategory>) =>
  of(
    itemTypesCategoriesSlice.actions.fetchByIdSuccess({
      entity,
    })
  );

const fetchByIdErrorHandler = (error: Error) =>
  of(
    itemTypesCategoriesSlice.actions.fetchByIdError({
      error: error.message,
    }),
    notificationSlice.actions.addErrorNotification({
      message:
        "Une erreur s'est produite lors de la récupération du modèle de contenant.",
    })
  );

const fetchByIdEpic: Epic = (
  action$: ActionsObservable<
    ActionType<typeof itemTypesCategoriesSlice.actions.fetchById>
  >,
  state$
) =>
  action$.pipe(
    filter(isOfType(itemTypesCategoriesSlice.actions.fetchById.type)),
    switchMap(({ payload }) => {
      const token = currentUserTokenSelector(state$.value) as string;

      return from(
        itemTypesCategoriesApi.fetchById({ id: payload.id, token })
      ).pipe(
        switchMap(fetchByIdSuccessHandler),
        catchError(fetchByIdErrorHandler),
        startWith(notificationSlice.actions.clean())
      );
    })
  );

export default fetchByIdEpic;

import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { createEpicMiddleware } from "redux-observable";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

// Constants
import { PERSIST_CONFIG } from "./constants";

// Epics
import rootEpic from "./rootEpic";

// Reducers
import sliceReducers from "./sliceReducers";

// Slices
import { agreementsWithTransactionsApi } from "./features/agreements/agreements-with-transactions-slice";
import { leasingAgreementsApi } from "./features/agreements/leasing-agreements-slice";
import { cautionsAPI } from "./features/cautions/slice";
import { collectorsApi } from "./features/collectors/slice";
import { currentUserSlice } from "./features/currentUser/slice";
import { externalDistributorConfigurationApi } from "./features/externalDistributorConfiguration/slice";
import { gameConfigApi } from "./features/gamesConfiguration/slice";
import { itemCategoryApi } from "./features/itemCategories/slice";
import { itemModelApi } from "./features/itemModels/slice";
import { partnersApi } from "./features/partners/slice";
import { resetPasswordAPI } from "./features/resetPassword/slice";
import { siteNetworkApi } from "./features/siteNetworks/slice";
import { boxTypeV3ConfigurationsApi } from "./features/sites/boxTypeV3Configurations/slice";
import { etfOdysseyConfigurationApi } from "./features/sites/etfConfiguration/slice";
import { lessorConfigurationApi } from "./features/sites/leasing/lessorConfiguration/slice";
import { paymentSytemConfigurationApi } from "./features/sites/leasing/paymentSystemConfiguration/slice";
import { partnerApiAccessApi } from "./features/sites/partnerAccessApi/slice";
import { userApi } from "./features/users/api/slice";

const epicMiddleware = createEpicMiddleware();

export const history = createBrowserHistory();

const reducer = combineReducers({
  router: connectRouter(history),
  ...sliceReducers,
});

const rootReducer: typeof reducer = (state, action) => {
  if (action.type === currentUserSlice.actions.logout.type) {
    state = undefined;
  }

  return reducer(state, action);
};

export const store = configureStore({
  reducer: persistReducer(PERSIST_CONFIG, rootReducer),
  middleware: [
    thunk,
    epicMiddleware,
    routerMiddleware(history),
    gameConfigApi.middleware,
    cautionsAPI.middleware,
    lessorConfigurationApi.middleware,
    externalDistributorConfigurationApi.middleware,
    paymentSytemConfigurationApi.middleware,
    partnerApiAccessApi.middleware,
    resetPasswordAPI.middleware,
    collectorsApi.middleware,
    partnersApi.middleware,
    agreementsWithTransactionsApi.middleware,
    leasingAgreementsApi.middleware,
    siteNetworkApi.middleware,
    userApi.middleware,
    itemModelApi.middleware,
    itemCategoryApi.middleware,
    boxTypeV3ConfigurationsApi.middleware,
    etfOdysseyConfigurationApi.middleware,
  ],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

epicMiddleware.run(rootEpic);

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import getApiUrl from "modules/api/getApiUrl";
import { RootState } from "store";
import { currentUserTokenSelector } from "store/features/currentUser/selectors";
import { notificationSlice } from "store/features/notification/slice";
import { ItemTopology } from "types/entities/distributions/distribution";
import { AgreementType } from "types/entities/site/lessorConfiguration";
import { generateErrorMessage } from "util/generateErrorMessage";

type LeasingAgreement = {
  id: number;
  lessorId: number;
  lesseeId?: number;
  creationDate: Date; // utc iso 8601, agreement creation date
  agreementType: AgreementType;
  agreementConfiguration:
    | {
        expiryDate: Date | null;
        penaltyFee: number;
      }
    | {
        cashedDepositAmount: number;
      };
  item: {
    itemId?: number;
    itemTypeId?: number;
    itemTopology?: ItemTopology;
  };
  agreementStatus: AgreementStatus;
  paymentRequests: PaymentRequest[];
  refundRequests: RefundRequest[];
};

type AgreementStatus = {
  state: string;
  borrowId?: number;
  distributionTransactionRef?: number;
  legacyDistributionRef?: number;
  borrowDate?: Date;
  returnId?: number;
  returnTransactionId?: number;
  returnDate?: Date;
  paymentStatus?: string;
  refundStatus?: string;
  cancelDate?: Date;
};

type PaymentRequest = {
  paymentRequestId: number;
  requestDate: Date;
  statusUpdatedDate: Date;
  status: string;
  error: string | null;
  initiatorRequestRef: string;
  initiaterRequestRef: string;
  /**
   * The id of the payment saved on financial domain side.
   */
  paiementRequestRef: number | null;
  amount: number;
  currency: "EUR";
  itemTypeId: number | null;
  itemTopology: ItemTopology | null;
};

type RefundRequest = {
  refundRequestId: number | null;
  requestDate: Date;
  statusUpdatedDate: Date;
  status: string;
  error: string | null;
  initiatorRequestRef: string | null;
  initiaterRequestRef: string | null;
  /**
   * The ID of the payment saved on financial domain side.
   */
  refundRequestRef: number | null;
  amount: number;
  currency: "EUR";
  itemTypeId: number | null;
  itemTopology: ItemTopology | null;
};

export const leasingAgreementsApi = createApi({
  reducerPath: "leasingAgreementsApi",

  baseQuery: fetchBaseQuery({
    baseUrl: getApiUrl(""),
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = currentUserTokenSelector(state);
      if (token) headers.set("x-auth", `${token}`);
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getAgreementById: builder.query<LeasingAgreement, number>({
      query: (id) => ({
        method: "GET",
        url: `/leasing/agreements/${id}`,
      }),
    }),
    refundAgreement: builder.mutation<
      LeasingAgreement,
      { agreementId: number; refundReason?: string }
    >({
      query: ({ agreementId, refundReason }) => ({
        method: "POST",
        url: `/leasing/agreements/${agreementId}/refund`,
        body: {
          refundReason,
        },
      }),
      onQueryStarted: async (_entity, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (e) {
          dispatch(
            notificationSlice.actions.addErrorNotification({
              message: `<p>Une erreur s'est produite lors du remboursement.</p><p>${generateErrorMessage(
                e
              )}</p>`,
            })
          );
        }
      },
    }),
  }),
});
